<template>
    <div class="ma-5">
        <v-container>
            <v-row class="mb-4">
                <v-img :src="info.picture" class="rounded-xl mb-2"></v-img>
                <h1>{{ info.name }}
                    <v-icon v-for="(color, index) in info.band_color" :key="index"
                        :style="{ color: color + ' !important' }">
                        mdi-circle
                    </v-icon>
                </h1>
                <h4>{{ info.comment }}</h4>
                <p class="comment">
                    <span v-if="info.gender !== '?'">
                        {{ info.gender === 'male' ? 'おとこのこ。' : 'おんなのこ。' }}
                    </span>
                    誕生日は{{ info.birthday }}。
                    <span v-if="info.pair">ペアは{{ info.pair }}。</span>
                    {{ info.place }}のペンギン。
                    {{ info.comment }}
                </p>
            </v-row>


            <!-- <v-row justify="center" class="ma-4">
                <v-dialog width="500">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" v-bind="attrs" v-on="on" rounded width="250" large>
                            この子だった！！！
                        </v-btn>
                    </template>

                        <v-card>
                            <v-card-title class="blue lighten-2 white--text">
                                どちらかえらんでね
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-card-actions>
                                <v-btn color="primary" text @click="sendDB('draw-penguin')">
                                    ほかのペンギンをかく
                                </v-btn>
                            </v-card-actions>
                            <v-card-actions>
                                <v-btn color="primary" text @click="sendDB('')">
                                    さいしょにもどる
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                </v-dialog>
            </v-row> -->
            <v-row justify="center" class="ma-4">
                <v-btn color="primary" rounded width="250" large @click="sendDB('')">
                    この子だった！！！
                </v-btn>
            </v-row>

            <v-row justify="center" class="ma-4">
                <v-btn @click="goBack()" color="gray" rounded width="250" large>この子じゃなかった</v-btn>
            </v-row>
        </v-container>
    </div>
</template>


<script>
export default {
    // props: ['aquarium'], // ルーターから受け取るプロップス
    data() {
        return {
        };
    },
    props: {
        aquarium: String,
        info: Object,
        rank: Number,
        all_info: Array
    },
    mounted() {
        this.$returnScroll();
    },
    created() {
        this.$returnScroll();
    },
    methods: {
        goBack() {
            this.$router.push(`/${this.aquarium}/ranking`);
        },
        sendDB(path) {
            // drawingテーブルに送信(現状わからなかった(rank=0)だとデータが送信されないので直したい)
            let penguin_name = "Unknown";
            if (this.rank > 0) {
                penguin_name = this.all_info[this.rank - 1]?.name || 'Unknown';
            }
            const data = {
                mail: this.$store.state.user.mail,  // ユーザーを識別するメールアドレス
                uid: this.$store.state.user.uid,
                aquarium: this.aquarium,
                penguin_name: penguin_name,
                image: this.$store.state.imgURL
            };
            this.$axios
                .post("https://nakagawa.nkmr.io/api/pensaku/post_drawing.php", data)
                .then((response) => {
                    console.log("status:", response.status);
                    // alert("送信しました");
                })
                .catch((error) => {
                    // alert("エラーが発生しました");
                    console.log("err:", error);
                });



            //サーバーに保存する作業
            var params = new URLSearchParams();
            params.append("name", this.$store.state.user.name);
            params.append("uid", this.$store.state.user.uid);
            params.append("aquarium", this.aquarium);
            params.append("penname", this.$store.state.penName);
            params.append("bandcolor", JSON.stringify(this.$store.state.bandColor));
            params.append("image", this.$store.state.imgURL);
            params.append("time", this.$store.state.drawTime);
            params.append("data", JSON.stringify(this.$store.state.data));
            params.append("ranking", JSON.stringify(this.all_info));
            params.append("part", JSON.stringify(this.$store.state.part));
            params.append("rank", this.rank);

            this.$axios
                .post("https://nakagawa.nkmr.io/api/pensaku/post_all.php", params)
                .then((response) => {
                    console.log("status:", response.status);
                    // alert("送信しました");
                })
                .catch((error) => {
                    // alert("エラーが発生しました");
                    console.log("err:", error);
                });

            this.dialog = false;
            this.$router.push(`/${this.aquarium}/${path}`);
        }
    }
}
</script>
