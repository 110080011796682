<template>
  <div>
    <h3 class="pt-5 pl-5">ペンギンの名前を入力してください</h3>
    <v-col cols="12" sm="6">
      <v-text-field
        label="名前(任意)"
        outlined
        clearable
        v-model="penName"
      ></v-text-field>
    </v-col>

    <h3 class="py-5 pl-5">ペンギンのバンドの色を選択してください</h3>

    <v-container class="py-0">
      <v-row align="center" justify="start">
        <v-col
          v-for="(selection, i) in selections"
          :key="selection.text"
          class="shrink"
        >
          <v-chip
            close
            @click:close="selected.splice(i, 1), bandColor.splice(i, 1)"
            :color="selection.color"
          >
            <!-- <v-icon left v-text="selection.icon" :color="selection.color"></v-icon> -->
            {{ selection.text }}
          </v-chip>
        </v-col>
      </v-row>

      <v-row justify="center" v-show="!bandColor.length == 0" class="pt-3">
        <v-btn large rounded color="primary" @click="toNext()">次へ</v-btn>
      </v-row>

      <v-row>
        <v-col v-if="!allSelected" cols="12">
          <v-text-field
            ref="search"
            v-model="search"
            full-width
            hide-details
            label="Search"
            single-line
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>

    <v-divider v-if="!allSelected"></v-divider>

    <v-list>
      <template v-for="item in items">
        <v-list-item
          v-if="!selected.includes(item)"
          :key="item.text"
          @click="selected.push(item), bandColor.push(item.text)"
        >
          <v-list-item-avatar>
            <v-icon v-text="item.icon" :color="item.color"></v-icon>
          </v-list-item-avatar>
          <v-list-item-title v-text="item.text"></v-list-item-title>
        </v-list-item>
      </template>
    </v-list>

    <v-divider></v-divider>
  </div>
</template>

<script>
export default {
  data: () => ({
    items: "",
    search: "",
    selected: [],
    bandColor: [],
    penName: null,
  }),
  created() {
    if (this.$store.state.aquaId == 0) {
      this.items = this.$store.state.sumidaColors;
    } else {
      this.items = this.$store.state.kyotoColors;
    }
  },
  computed: {
    allSelected() {
      return this.selected.length === this.items.length;
    },
    categories() {
      const search = this.search.toLowerCase();

      if (!search) return this.items;

      return this.items.filter((item) => {
        const text = item.text.toLowerCase();

        return text.indexOf(search) > -1;
      });
    },
    selections() {
      const selections = [];

      for (const selection of this.selected) {
        selections.push(selection);
      }

      return selections;
    },
  },

  watch: {
    selected() {
      this.search = "";
    },
  },

  methods: {
    toNext() {
      //   this.$store.commit("setPenName", this.penName); //入力された名前をvuexに送る
      this.$store.state.penName = this.penName;
      this.$store.state.bandColor = this.bandColor;
      this.$router.push("/draw-penguin");
    },
  },
};
</script>

<style>
div {
  touch-action: auto;
}
</style>