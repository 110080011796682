<template>
    <v-container>
        <v-row v-for="(chunk, chunkIndex) in chunkedAvatars" :key="chunkIndex" justify="center">
            <v-col v-for="(avatar, index) in chunk" :key="index" cols="4" class="d-flex flex-column align-center">
                <v-avatar size="85" class="clickable btn--circle" @click="onAvatarClick(avatar)">
                    <v-img :src="avatar.icon" />
                </v-avatar>
                <div class="text-center mt-2">
                    <span class="avatar-name">{{ avatar.name }}</span>
                    <span v-for="(color, colorIndex) in avatar.band_color" :key="colorIndex"
                        :style="{ backgroundColor: color }"
                        :class="{ 'color-rectangle': true, 'white-border': color === 'white' }"></span>
                </div>
            </v-col>
        </v-row>

        <!-- モーダル -->
        <v-dialog v-model="dialog" max-width="400px" @click:outside="dialog = false">
            <v-card>
                <v-card-title>
                    <span class="headline">{{ selectedAvatar.name }}<span
                            v-for="(color, index) in selectedAvatar.band_color" :key="index"
                            :style="{ backgroundColor: color }"
                            :class="{ 'color-rectangle-modal': true, 'white-border': color === 'white' }">
                        </span></span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="dialog = false" class="close-btn" color="blue darken-1"> <!-- モーダル右上の×ボタン -->
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-img :src="selectedAvatar.picture" max-width="100%" />
                    <span v-if="selectedAvatar.gender !== '?'">
                        {{ selectedAvatar.gender === 'male' ? 'おとこのこ。' : 'おんなのこ。' }}
                    </span>
                    たんじょうびは{{ selectedAvatar.birthday }}。
                    <span v-if="selectedAvatar.pair">ペアは{{ selectedAvatar.pair }}。</span>
                    {{ selectedAvatar.place }}のペンギン。
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialog = false">とじる</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-container>
</template>

<script>
// import axios from 'axios';
export default {
    props: ['aquarium'], // ルーターから受け取るプロップス
    data() {
        return {
            avatars: '',
            dialog: false,
            selectedAvatar: {
                name: '',
                icon: '',
                band_color: []
            }

        };
    },
    computed: {
        chunkedAvatars() {
            // アバターを3つずつのグループに分ける
            const chunkSize = 3;
            const chunks = [];
            for (let i = 0; i < this.avatars.length; i += chunkSize) {
                chunks.push(this.avatars.slice(i, i + chunkSize));
            }
            return chunks;
        }
    },
    mounted() {
        this.fetchData(this.aquarium);
        this.$returnScroll();
    },
    created() {
        this.$returnScroll();
    },
    methods: {
        onAvatarClick(avatar) {
            this.selectedAvatar = avatar;  // クリックされたアバターを選択
            this.dialog = true;  // モーダルを開く
        },
        async fetchData(fileName) {
            try {
                const response = await this.$axios.get(`https://nakagawa.nkmr.io/api/response_json.php?file=${fileName}`);
                this.avatars = response.data;
                // console.log(response.data)
            } catch (error) {
                console.error('データの取得に失敗しました:', error);
                if (error.response) {
                    console.error('レスポンスエラー:', error.response);
                } else if (error.request) {
                    console.error('リクエストエラー:', error.request);
                } else {
                    console.error('その他のエラー:', error.message);
                }
            }
        },
    }
};
</script>

<style scoped>
.clickable {
    cursor: pointer;
}

.btn--circle {
    border-radius: 50%;
    line-height: 100px;
    width: 100px;
    height: 100px;
    padding: 0;
    -webkit-box-shadow: 0 5px 0 #c4ddf9;
    /* Vuetifyのブルー系統の色 */
    box-shadow: 0 5px 0 #c4ddf9;
    /* Vuetifyのブルー系統の色 */
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #c4ddf9;
    /* Vuetifyのブルー系統の色 */
    color: white;
    border: 2.5px solid rgb(0, 142, 207);
}

.btn--circle:hover {
    -webkit-transform: translate(0, 3px);
    transform: translate(0, 3px);
    -webkit-box-shadow: 0 2px 0 #c4ddf9;
    /* Vuetifyのブルー系統の色 */
    box-shadow: 0 2px 0 #c4ddf9;
    /* Vuetifyのブルー系統の色 */
}

.color-rectangle {
    display: inline-block;
    width: 8px;
    height: 13px;
    margin-left: 1px;
    border-radius: 2px;
    /* border: 1px solid black; */
}

.color-rectangle-modal {
    display: inline-block;
    width: 13px;
    height: 23px;
    margin-left: 4px;
    border-radius: 2px;
    /* border: 1px solid black; */
}

.white-border {
    border: 0.5px solid black;
    /* 白色の場合の枠線 */
}

.avatar-name {
    font-weight: bold;
    font-size: 0.8rem;
    /* フォントサイズを小さく調整 */
}

.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    color: blue;
}
</style>